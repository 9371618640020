<template>
	<div class="DeviceSrvList">
		<div v-if="!(by==1 || by==2)">
			<el-button v-if="by==1" size="mini" type="primary" @click="closeDevFunListDrawer()">关闭</el-button>
			<el-button v-if="by==1" size="mini" type="primary" @click="serviceCheck()">生成默认服务</el-button>
			<el-button size="mini" type="primary" @click="refresh()">查询</el-button>
		</div>
		
		<table v-if="plist && plist.length > 0" class="configItemTalbe" width="99%">
		    <thead>
				<tr><td>{{"name"|i18n}}</td>
				<td>{{'deviceName'|i18n}}</td>
				<td>{{'boardType'|i18n}}</td>
				<td>{{'boardName'|i18n}}</td>
				<td>{{'type'|i18n}}</td>
				<td>{{'DevStatus'|i18n}}</td>
				<td>{{'SrvName'|i18n}}</td>
				<td>{{'SrvStatus'|i18n}}</td>
				<td>{{"Operation"|i18n}}</td>
				</tr>
		    </thead>
		    <tr v-for="c in plist" :key="'h_'+c.id">
		        <td>{{c.name}}</td>
				<td>{{deviceMap[c.deviceId]}}</td>
				<td>{{c.boardType}}</td>
				<td>{{c.boardName}}</td>
				<td>{{c.type}}</td> 
				<td>{{c.itStatus==1?'启用':'禁用'}}</td>
				<td>{{c.itName}}</td>
				<td>{{c.itStatus==1?'启用':'禁用'}}</td>
				<td>
					<el-button size="mini" type="primary" @click="viewDetail(c)">查看</el-button>
					<el-button size="mini" type="primary" @click="updateDetail(c)">更新</el-button>
				</td>
		    </tr>
		</table>
		
		<div v-if="isLogin && plist && plist.length > 0" style="position:relative;text-align:center;">
		    <Page ref="pager" :total="totalNum" :page-size="queryParams.size" :current="queryParams.curPage"
		          show-elevator show-sizer show-total @on-change="curPageChange"
		          @on-page-size-change="pageSizeChange" :page-size-opts="[10, 30, 60,100]"></Page>
		</div>
		
		<div v-if="!isLogin">
		    No permission!
		</div>
		
		<div v-if="!plist || plist.length == 0">
		    No data!
		</div>
		
		<Drawer ref="detailInfo" v-model="detailDrawer.drawerStatus" :closable="false" placement="right"
			:transfer="false" :styles="detailDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" width="40"
			:mask-closable="false" :mask="false">
			
			<el-row>
				<el-button size="mini" @click="detailDrawer.drawerStatus = false">关闭</el-button>
				<el-button v-if="model!=3" size="mini" type="primary" @click="doUpdate()">保存</el-button>
			</el-row>

			<el-row>
				<el-col>名称</el-col>
				<el-col><el-input v-model="temp.name" :disabled="model==3" /></el-col>
			</el-row>
			
			<el-row>
				<el-col>设备启用状态</el-col>
				<el-select style="width:100%" v-model="temp.status" :disabled="model==3" placeholder="请选择">
					<el-option :key="1" :value="1" label="启用"></el-option>
					<el-option :key="0" :value="0" label="禁用"></el-option>
				</el-select>
			</el-row>
			
			<el-row>
				<el-col>设备服务描述</el-col>
				<el-col><el-input v-model="temp.desc" :disabled="model==3" /></el-col>
			</el-row>
			<el-row>
				<el-col>UI模板 
					<a v-if="!templateStr && temp.si.templateId"
					 @click="refleshTemplate(temp.si.templateId)">{{'Refresh'|i18n}}</a>
				</el-col>
				<el-col>
					 <el-input v-model="templateStr" type="textarea" :rows="10" :disabled="model==3"></el-input>
				</el-col>
			</el-row>
			<br/><br/>
			<el-row>
				<el-col>模板ID</el-col>
				<el-col><el-input v-model="temp.si.templateId" :disabled="true"/></el-col>
			</el-row>
			
			<el-row>
				<el-col>服务名称</el-col>
				<el-col><el-input v-model="temp.si.name" :disabled="true" /></el-col>
			</el-row>
			
			<el-row>
				<el-col>设备类型</el-col>
				<el-col>
					<el-input v-model="temp.boardType" :disabled="true" />
				</el-col>
			</el-row>
			
			<el-row>
				<el-col>设备类型名称</el-col>
				<el-col>
					<el-col><el-input v-model="temp.si.boardName" :disabled="true"/></el-col>
				</el-col>
			</el-row>
			<el-row>
				<el-col>设备类型名称</el-col>
				<el-col>
					<el-input v-model="temp.boardName" :disabled="true" />
				</el-col>
			</el-row>
			<el-row>
				<el-col>服务类型</el-col>
				<el-col>
					<el-input v-model="temp.type" :disabled="true" />
				</el-col>
			</el-row>
			
			 <el-row>
				<el-col>所属产品</el-col>
				<el-col>
					<el-col><el-input v-model="prdMap[temp.si.productId]" :disabled="true" /></el-col>
				</el-col>
			</el-row>
			<!--
			<el-row>
				<el-col>{{"ClientId"|i18n}}</el-col>
				<el-col>
					<el-col><el-input v-model="temp.clientId" :disabled="true" /></el-col>
				</el-col>
			</el-row> -->
			
			<el-row>
				<el-col>状态查询接口ID</el-col>
				<el-col>
					<el-input v-model="temp.si.dataQryName" :disabled="true"/>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col>状态查询接口名称</el-col>
				<el-col>
					<el-input v-model="temp.si.dataQryDefId" :disabled="true"/>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col>查询间隔(单位秒,需大于或等于3秒,等于0表示不自动查询)</el-col>
				<el-col><el-input v-model="temp.si.qryInterval" :disabled="true" /></el-col>
			</el-row>
			
			<el-row>
				<el-col>服务启用状态</el-col>
				<el-col><el-input v-model="temp.itStatus==1?'启用':'禁用'" :disabled="true" /></el-col>
			</el-row>
			
			<el-row>
				<el-col>服务描述</el-col>
				<el-col><el-input v-model="temp.si.desc" :disabled="true" /></el-col>
			</el-row>
			
			<el-row>
				<el-col>服务ID</el-col>
				<el-col><el-input v-model="temp.itemId" :disabled="true" /></el-col>
			</el-row>
			
			<el-row>
				<el-col>服务账号</el-col>
				<el-col><el-input v-model="temp.si.createdBy" :disabled="true"/></el-col>
			</el-row>
			<br/>
			
			<el-row>
				注意：修改改标签名完成后，要点击上方的"保存"按钮才能生效！
			</el-row>
			<table v-if="temp.si.ops && temp.si.ops.length > 0" class="configItemTalbe" width="99%">
			    <thead>
			        <td>{{'Label'|i18n}}</td><td>{{'Name'|i18n}}</td>
					<td>{{'opId'|i18n}}</td>
					<td>{{'qryOpId'|i18n}}</td>
					<td>{{'qryName'|i18n}}</td>
			        <td>{{"Operation"|i18n}}</td>
			    </thead>
			    <tr v-for="c in temp.si.ops" :key="'h_'+c.name">
					  <td>{{getOpLabel(c)}}</td> <td>{{c.name}}</td> <td>{{c.opId}}</td>
					  <td>{{c.qryOpId}}</td><td>{{c.qryName}}</td>
			        <td>
					    <a v-if="model==1" @click="updateLabel(c)">{{'改标签名'}}</a>
			        </td>
			    </tr>
			</table> 
			
		</Drawer>
		
		<!-- 改名对话框 -->
		<el-dialog
		  title="服务标题"
		  :visible.sync="showUpdateLabel"
		  width="30%"  :modal="false"  :close-on-click-modal="false" 
		  :append-to-body="true"
		>
		
		  <el-input
			v-model="labelParam.label"
			placeholder="请输入服务标题"
		  ></el-input>
			
		  <span slot="footer" class="dialog-footer">
			<el-button @click="cancelUpdateLabel()">取消</el-button>
			<el-button type="primary" @click="comfirmUpdateLabel()">确定</el-button>
		  </span>
		</el-dialog>

	</div>
</template>

<script>
	
	const cid = 'DeviceSrvList'

	export default {
		name: cid,
		
		props:{
			from:{type:Boolean,default:false},
		},
		
		data() {
			return {
				by:this.$jm.IOT.JM_OP_SRC.PRODUCT,//1:产品， 2： 设备
				dev:{},
				product:{},
				productId:0,
				
				p: {},
				addOrUpdateDialog: false,
				model: 3,
				
				prdMap:{},
				deviceMap:{},
				defList:[],
				selDef:{},
				
				errorMsg:'',
				isLogin:true,
				plist: [],

				queryParams:{size:30,curPage:1,ps:{selectType:0}},
				totalNum:0,
				
				dels:{},
				adds:{},
				
				temp:{si:{}},
				oldName:null,
				oldTemp:{},
				templateStr:null,
				
				templateMap:{},
				
				oldLabelVal:null,
				showUpdateLabel:false,
				labelParam:{},
				labelNew:false,
				labelChange:false,
				
				detailDrawer: {
				    drawerStatus : false,
				    drawerBtnStyle : {left:'0px',zindex:1000},
				},
			}
		},

		methods: {
			
			cancelUpdateLabel(){
				this.showUpdateLabel = false
				this.labelParam.label = this.oldLabelVal
				this.labelChange = false
			},
			
			updateLabel(siOp) {
				//this.labelParam = siOp
				if(this.temp.opsLabel &&　this.temp.opsLabel.length) {
					this.labelParam = this.temp.opsLabel.find(e=>e.opId == siOp.opId)
				}
				
				if(!this.labelParam) {
					this.labelParam = {opId:siOp.opId, label:siOp.label}
				}
				
				this.oldLabelVal = this.labelParam.label
				
				this.labelChange = false
				this.showUpdateLabel=true
			},
			
			comfirmUpdateLabel() {

				if(this.temp.opsLabel &&　this.temp.opsLabel.length 
					&& this.temp.opsLabel.find(e=>e.opId == this.labelParam.opId)) {

				} else {
					this.temp.opsLabel.push(this.labelParam)
					this.$forceUpdate()
				}
				//console.log("comfirmUpdateLabel",this.temp.opsLabel)
				
				this.labelChange = this.labelParam.label != this.oldLabelVal
				
				//console.log("comfirmUpdateLabel",this.labelParam.label, this.oldLabelVal, this.labelChange)
				
				this.showUpdateLabel=false
				
				//this.$forceUpdate()
			
			},
			
			getOpLabel(siOp){
				if(this.temp.opsLabel &&　this.temp.opsLabel.length) {
					let opLabel = this.temp.opsLabel.find(e=>e.opId == siOp.opId)
					if(opLabel) return opLabel.label
				}
				return siOp.label
			},
			
			async refleshTemplate(templateId){
				if(this.templateMap[templateId]) {
					//this.temp.template = this.templateMap[templateId]
					this.templateStr = this.templateMap[templateId]
					return;
				}
				
				let r = await this.$jr.rpc.invokeByCode(-496937706, [templateId])
				console.log("refleshTemplate rst", r)
				if(r.code == 0) {
					//this.temp.template = r.data
					this.templateStr = r.data
					this.templateMap[templateId] = r.data
					//this.$forceUpdate()
				}
			},
			
			async viewDetail(c) {
				this.model = 3
				this.temp = c
				
				if(!this.temp.si.id) {
					let si = await this.getSrvItem(this.temp.itemId)
					if(si) this.temp.si = si
				}
				
				this.templateStr = c.template
				
				this.detailDrawer.drawerStatus = true
			},
			
			async updateDetail(c) {
				this.model = 1
				this.temp = c
				this.oldName = this.temp.name
				this.oldTemp = Object.assign({},c)
				
				if(!this.temp.si.id) {
					let si = await this.getSrvItem(this.temp.itemId)
					if(si) this.temp.si = si
				}
				
				this.templateStr = c.template
				console.log("updateDetail", this.temp)
				
				this.detailDrawer.drawerStatus = true
			},
			
			serviceCheck(){
				if(this.by ==1){
					this.$jr.rpc.invokeByCode(-1598543300, [this.dev.deviceId])
					.then((resp) => {
						if (resp.code) {
							this.$notify.error({title: '错误',message: resp.msg || "未知错误"});
						}
						this.refresh()
					}).catch((err) => {
						this.$notify.error({title: '错误',message: err});
					});
				}
			},
			
			async getSrvItem(itemId) {
				let r = await this.$jr.rpc.invokeByCode(338265773, [itemId])
				console.log("getSrvItem rst", r)
				if(r.code == 0) {
					return r.data
				}else {
					console.log("getSrvItem ERR",r)
					return null
				}
			},
			
			async doUpdate(){
				
				let srcTemplate = this.templateMap[this.temp.si.templateId]
				
				if(this.temp.status == this.oldTemp.status &&
					this.temp.name == this.oldTemp.name &&
					this.temp.desc == this.oldTemp.desc &&
					!this.labelChange &&
					( this.templateStr == this.oldTemp.template || 
					(srcTemplate && this.templateStr == srcTemplate) ) ) {
						
					console.log("templateStr",this.templateStr)
					console.log("template",this.oldTemp.template)
					console.log("srcTemplate",srcTemplate)
						
					this.$notify.error({title: '提示',message: "无需更新"})
					return;
				}
				
				if(srcTemplate && srcTemplate==this.templateStr) {
					this.temp.template = null
				} else {
					this.temp.template = this.templateStr
				}

				let si = this.temp.si
				this.temp.si = {}
				let r = await this.$jr.rpc.invokeByCode(-247846820, [this.temp]);
				this.temp.si = si
				console.log("doUpdate rst: ", r);
				if(r.code != 0) {
					this.$notify.error({title: '错误',message: r.msg})
				} else {
					this.$notify.info({title: '提示',message: "更新成功"})
				}
			},
			
			closeDevFunListDrawer(){
				if(this.by==1) this.$parent.$parent.closeDevSrvListDrawer()
			},
			
			curPageChange(curPage){
				this.queryParams.curPage = curPage
			    this.refresh()
			},
			
			pageSizeChange(pageSize){
				this.queryParams.size = pageSize
				this.queryParams.curPage = 1
			    this.refresh()
			},
			
			refresh() {
			    let self = this
			    this.isLogin = this.$jr.auth.isLogin()
			    if(this.isLogin) {
			        let params = this.getQueryConditions()
			        let self = this
					
					console.log(this.dev, params)
					
					//listProductFuns
			        this.$jr.rpc.invokeByCode(-1231073421, [params])
			            .then((resp)=>{
			                if(resp.code == 0){
			                    if(resp.total == 0) {
									this.plist = []
									this.totalNum = 0
			                        this.$notify.info({title: '提示',message: "查无数据"})
			                    } else {
									resp.data.forEach(e=>{
										e.si = {ops:[]}
									})
									console.log("qplist",resp.data)
									this.plist = resp.data
									this.totalNum = resp.total
			                    }
			                } else {
								this.plist = []
								this.totalNum = 0
			                    this.$notify.error({title: '提示',message: resp.msg})
			                }
			            }).catch((err)=>{
			            window.console.log(err)
			        });
			    } else {
			        self.roleList = []
			    }
			},
			
			getQueryConditions() {
				if(this.by == 1) {
					this.queryParams.ps.deviceId = this.dev.deviceId
				}else if(this.by == 2) {
					this.queryParams.ps.itemId = this.dev.id
				}

			    return this.queryParams;
			},
			
			loadDataByDev(dev, prdMap){
				 console.log("loadDataByDev")
				this.by = 1 //从设备进来
				this.dev = dev
				this.deviceMap[dev.deviceId] = dev.name
				this.prdMap = prdMap
				this.plist = []
				this.totalNum = 0
				this.refresh()
			},
			
			loadDataBySrvItem(srvItem, prdMap, devMap){
				console.log("loadDataBySrvItem")
				this.by = 2  //从服务选项进来
				this.dev = srvItem
				this.prdMap = prdMap
				this.deviceMap = devMap				
				this.plist = []
				this.totalNum = 0
				this.refresh()
			},

		},

		async mounted () {
		    console.log("DeviceSrvList mounted",this.from)
			this.plist = []
			this.totalNum = 0
			if(this.from) {
				return
			}
			
			this.$jr.auth.addActListener(this.refresh);
			this.prdMap = await this.$jm.getPrdMap();
			this.deviceMap = await this.$jm.getDevices();
			
			this.refresh();
			let self = this;
			this.$bus.$emit("editorOpen",
			    {"editorId":cid, "menus":[
			        {name:"REFRESH",label:"Refresh",icon:"ios-cog",call:self.refresh}
					]
			    });
			
			let ec = function() {
			    this.$jr.auth.removeActListener(cid);
			    this.$off('editorClosed',ec);
			}
			
			this.$bus.$on('editorClosed',ec);
			
		},
		
		beforeDestroy() {
		    this.$jr.auth.removeActListener(cid)
		},

	}
</script>

<style>
	.DeviceSrvList {
		border-top: 1px dotted lightgray;
		margin-top: 6px;
		padding-top: 10px;
		text-align: left;
	}
	
	.title{
		font-weight: bold;
		font-size: 17px;
	}
	
	.valCol{
		overflow: hidden;
		text-overflow: ellipsis;
		flex-wrap: nowrap;
	}
	
	/* 对话框样式 */
	.el-dialog {
	  border-radius: 8px;
	  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	}
	
	/* 标题样式 */
	.el-dialog__title {
	  font-size: 18px;
	  font-weight: bold;
	  color: #333;
	}
	
	/* 输入框样式 */
	.el-input {
	  margin-top: 20px;
	}
	
	/* 按钮样式 */
	.dialog-footer {
	  display: flex;
	  justify-content: flex-end;
	}
</style>
